.navbar {
  height: 60px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.line {
  width: 100%;
  height: 0%;
  background-color: rgb(232, 143, 10);
  border: 3px solid rgb(232, 143, 10);
}
nav .collapse {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.above_navbar_div {
  margin: 0 !important;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: none;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.nav-item {
  margin: 0px 10px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.navbar .sharva-logo {
  padding: 5px;
}
.nav-item button {
  font-size: 15px;
  font-weight: 600;
}

@media only screen and (max-width: 425px) {
  .navbar {
    width: 100%;
    height: 70px !important;
  }
  .above_navbar_div .collapse {
    justify-content: center;
    background-image: linear-gradient(316deg, #ff9100, #ffbe0b 74%);
  }
  .nav-item {
    flex-direction: column;
  }
}
/* .navbar-toggler {
  width: 28px;
  height: 28px;
  position: relative;
  transition: 0.5s ease-in-out;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navvar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}
.navbar-toggler span {
  margin: 0;
  padding: 0px;
}
.toggler-icon {
  display: block;
  position: absolute;
  height: 13px;
  width: 100%;
  background-image: linear-gradient(316deg, #ff9100, #ffbe0b 74%);
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(deg);
  transition: 0.25s ease-in-out;
}
.middle-bar {
  margin-top: 0px;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}
.navbar-toggler .bottom-bar {
    margin-top: 0px;
    transform: rotate(-135deg);
  }
.navbar-toggler.collapsed .top-bar {
    margin-top: -20px;
   transform: rotate(0deg);
  }
  .navbar-toggler.collapsed .middle-bar {
 opacity: 1;
 filter: alpha(opacity=100);

  }
  .navbar-toggler.collapsed .bottom-bar {
    margin-top: 20px;
    transform: rotate(0deg);
   
     } */


     .navbar-toggler-icon {
        background-image: url("../images/hamburger.png");
      }

 