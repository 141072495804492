


#style-2::-webkit-scrollbar-track
{
  box-shadow: none;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  box-shadow: none;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background:  linear-gradient(316deg, #ff9100, #ffbe0b 74%);
}


.checkout_header {
  font-size: 20px;
  text-align: center;
  font-weight: 900;
  margin: 50px;
}
.checkout-wrapper {
  /* height: 600px; */
width: 50%;  background: #f5f5f5;
  border-top: 5px solid rgb(232, 143, 10);
}
#style-8::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.checkout-wrapper .scrollbar {

  float: left;
  height: 600px;
  overflow-x: hidden;

  overflow-y: scroll;

}

/** STYLE 7 */
#style-7::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(316deg, #ff9100, #ffbe0b 74%);
}

.form_inputs {
  padding: 15px 0 ;
  border: none;
  background-color: #f5f5f5;
  border-bottom: 1px solid #c5c5c5 !important;
  outline: none;
  border-radius: 0px !important;
  font-size: 16px;
}
.volenteer-img img{
  width: 100%;
  /* height: 400px; */
  
  padding:0px  20px;
}
.volenteer-img {
  width: 50%;
 
}
.CheckOut {
  height: fit-content !important;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CheckOut .form-input {
  display: flex;
  flex-direction: row;
}

.CheckOut .form-input label {
  padding: 0px 5px;
  margin: 0px 5px;
}
.CheckOut .checkout-content {
  display: flex;
  flex-direction: row;
}
/* .CheckOut .form-input input {
  padding: 0px 5px;
  margin: 0px 5px;
  border: none;
  background-color: #f5f5f5 !important ;
} */
.checkout-wrapper .order-review .accordion-item .accordion-button::after {
  fill: #6a1818;
  height: 0.7em;
  width: 0.7em;
  background-size: 0.7em;
  /* background-image: url('../images/arrow.svg'); */
}
.join_background_image {
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: linear-gradient(to top, #0000004f, #00000071),
    url("../images/sharva_joinus.jpg");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.joinus-heading{
  font-weight: 900;
}

.CheckOut .owl-theme {
  width: 40%;
  height: 600px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.CheckOut .owl-theme .img {
  width: 100%;
  height: 600px;
}.CheckOut .owl-theme .img img {
  width: 100%;
  height: 100%;
}
.CheckOut .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 21.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.CheckOut form textarea{
  padding: 15px 20px;
height: 150px;
  border: 1px solid #adadad;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
}
.CheckOut .textarea-joinus{
  display: flex;
  flex-direction: column;
}


.CheckOut .joinus_submit_btn{
  border-bottom: 0px !important;
  width: 100% !important;
}

@media (min-width: 320px) and (max-width: 540px) {
  .collapse {
    width: 100% !important;
  }

  .checkout-wrapper .checkout-form .form-input input,
  .checkout-section .checkout-wrapper .checkout-form .form-input input {
    padding: 5px 10px;
    
    
    outline: none;
 
    font-size: 16px;
  }
  .checkout-form .form-input {
    margin: 0;
    padding: 15px;
  }
  .p-20 {
    padding: 10px !important;
  }
  .CheckOut .checkout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.checkout-wrapper .scrollbar {
  margin-left: 0px;
  float: left;
  height: 600px;
  overflow-x: hidden;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 23px;
}
.CheckOut .joinus_submit_btn {
  border-bottom: 0px !important;
  width: 100% !important;

}
.volenteer-img img{
  padding: 20px;
}

.CheckOut .submitbutton{
  width: 100%;
  display: flex;
  margin: 10px;
  justify-content: center;
}
.CheckOut .owl-theme {
  width: 100%;
  height: auto;
  margin: 10px;
}
/* .CheckOut .owl-theme img {
  width: 100%;
  height: 400px;
} */
.initiatives .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 24.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.CheckOut .form-input label {
  padding: 0px ;
  margin: 0px;
}
.checkout-wrapper {
  height: 600px;
  width: 100%;

}
.volenteer-img {
  width: 100%;
}
.checkout-wrapper .scrollbar {
  margin-left: 0px;
  float: none;
  height: fit-content;
  overflow-x: none;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 23px;
}
.checkout-wrapper{
  height:  fit-content;
}
}
